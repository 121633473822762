<template>
  <div>
    <van-nav-bar class="nav-bar-div" :right-text="rightTextValue" @click-right="onClickRight" />
    <div class="maintext">
      <div class="title-div">

      </div>
      <div class="introduce-div">
        <p>{{ $t('custom.copywriting') }}</p>
      </div>
      <div class="content-div">
        <div class="content-field">
          <van-cell-group inset>

            <van-field :label="$t('custom.forms.fieldcustomerName.label')"
              :placeholder="$t('custom.forms.fieldcustomerName.placeholder')" required label-align="top"
              v-model="userinfo.customerName" />
            <van-field name="radio" required :label="$t('custom.forms.fieldgender.label')" label-align="top">
              <template #input>
                <van-radio-group class="custom-radio-group" v-model="userinfo.gender">
                  <van-row gutter="10">
                    <van-col span="6">
                      <van-radio name="0">{{ $t('custom.forms.fieldgender.dropdown.option1') }}</van-radio>
                    </van-col>
                    <van-col span="6">
                      <van-radio name="1">{{ $t('custom.forms.fieldgender.dropdown.option2') }} </van-radio>
                    </van-col>
                  </van-row>
                </van-radio-group>
              </template>
            </van-field>
            <van-field :label="$t('custom.forms.fieldcustomerAge.label')" type="number"
              :placeholder="$t('custom.forms.fieldcustomerAge.placeholder')" required label-align="top"
              v-model="userinfo.customerAge" />
            <van-field label-align="top" v-model="userinfo.acquisitionDate" required is-link readonly name="datePicker"
              :label="$t('custom.forms.fieldacquisitionDate.label')"
              :placeholder="$t('custom.forms.fieldacquisitionDate.placeholder')" @click="showPicker = true" />
            <van-popup v-model:show="showPicker" position="bottom">
              <van-date-picker @cancel="showPicker = false" :min-date="minDate" :max-date="maxDate" v-model="currentDate"
                @confirm="onBirthDateSUbmit()" />
            </van-popup>
            <van-field :label="$t('custom.forms.fieldContactInformation.label')"
              :placeholder="$t('custom.forms.fieldContactInformation.placeholder')" required label-align="top"
              v-model="userinfo.contactInformation" />
           
           
              <van-field :label="$t('custom.forms.fieldSampleSendingUnit.label')" 
              :placeholder="$t('custom.forms.fieldSampleSendingUnit.placeholder')" required label-align="top"
              v-model="userinfo.sampleSendingUnit" />

            <van-field v-model="dictValue" required label-align="top" is-link readonly
              :label="$t('custom.forms.fieldarea.label')" :placeholder="$t('custom.forms.fieldarea.placeholder')"
              @click="show = true" />

            <van-popup v-model:show="show" position="bottom">
              <van-cascader :title="$t('custom.forms.fieldarea.placeholder')" v-if="language == 'zh'" :options="options"
                @finish="onFinishFun" :field-names="fieldNames" @close="cascaderClosFun()" />

              <van-cascader :title="$t('custom.forms.fieldarea.placeholder')" v-else-if="language == 'en'"
                :options="options" @finish="onFinishFun" :field-names="fieldNamesT" @close="cascaderClosFun()" />

              <van-cascader :title="$t('custom.forms.fieldarea.placeholder')" v-else-if="language == 'jajp'"
                :options="options" @finish="onFinishFun" :field-names="fieldNamesJapanese" @close="cascaderClosFun()" />


              <van-cascader :title="$t('custom.forms.fieldarea.placeholder')" v-else-if="language == 'kokr'"
                :options="options" @finish="onFinishFun" :field-names="fieldNamesKorean" @close="cascaderClosFun()" />



            </van-popup>


            <van-field :label="$t('custom.forms.fieldSpecificUnit.label')"
              :placeholder="$t('custom.forms.fieldSpecificUnit.placeholder')" label-align="top"
              v-model="userinfo.specificUnit" />


            <van-field label-align="top" rows="5" :label="$t('custom.forms.fieldSymptom.label')"
              :placeholder="$t('custom.forms.fieldSymptom.placeholder')" show-word-limit autosize maxlength="500"
              v-model="userinfo.symptom" />
            <van-action-sheet v-model:show="i18nShwo" :actions="i18nActions" @select="oni18nSelect" />
          </van-cell-group>
          <div class="button-div">
            <van-button round block type="primary" native-type="submit" @click="onSubmit()">
              {{ $t('custom.forms.submitButton') }}
            </van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { qrCodeApi, submitHumanApi, treePageApi } from "@/api/index";
import { showToast, showSuccessToast } from 'vant';
export default {

  data() {
    return {
      language: 'zh',
      rightTextValue: '简体中文',
      showPicker: false,
      currentDate: ['2025', '03', '18'],
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2050, 5, 1),
      fieldNames: {
        text: 'dictValue',
        value: 'dictKey',
        children: 'childList',
      },
      i18nShwo: false,
      i18nActions: [

        { name: '简体中文', value: 'zh' },
        { name: '英语', value: 'en' },
        { name: '日语', value: 'jajp' },
        { name: '한국어', value: 'kokr' }
      ],
      fieldNamesT: {
        text: 'translate',
        value: 'dictKey',
        children: 'childList',
      },
      fieldNamesJapanese: {
        text: 'japanese',
        value: 'dictKey',
        children: 'childList',
      },

      fieldNamesKorean: {
        text: 'korean',
        value: 'dictKey',
        children: 'childList',
      },

      dictValue: '',
      show: false,
      options: [

      ],
      userinfo: {
        qrcodeInfoId: '',
        customerName: '',
        gender: -1,
        customerAge: null,
        acquisitionDate: '',
        contactInformation: '',
        sampleSendingUnit: '',
        symptom: '',
        country: '',
        economize: '',
        market: '',
        county: '',
        specificUnit: '',
        remark: ''
      },
    };
  },
  methods: {
    onClickRight() {
      this.i18nShwo = true;
      return;
      var varRightTextValue = this.rightTextValue;
      if (varRightTextValue == 'English') {
        this.rightTextValue = '中文'
      }
      if (varRightTextValue == '中文') {
        this.rightTextValue = 'English'
      }
      this.language = this.language === 'zh' ? 'en' : 'zh'
      // 切换语言
      this.$i18n.locale = this.language

    },
    oni18nSelect(item) {
      this.rightTextValue = item.name;
      this.language = item.value;
      this.$i18n.locale = item.value;




      if (item.value == 'zh') {
        this.columns = [
          { value: '瘙痒', text: '瘙痒' },
          { value: '腹泻拉稀', text: '腹泻拉稀' },
          { value: '眼耳发炎', text: '眼耳发炎' },
          { value: '皮肤红肿发炎', text: '皮肤红肿发炎' },
          { value: '皮膚丘疹紅斑', text: '皮膚丘疹紅斑' },
          { value: '其他', text: '其他' },


        ]
      }
      if (item.value == 'en') {
        this.columns = [
          { text: 'Itch', value: '瘙痒' },
          { text: 'Diarrhea', value: '腹泻拉稀' },
          { text: 'Infla,,ation of eyes and ears', value: '眼耳发炎' },
          {
            text: 'Skin redness and inflammation', value: '皮肤红肿发炎'
          },
          { text: 'Papule or Erythema in skin', value: '皮膚丘疹紅斑' },
          { text: 'other', value: '其他' },
        ]
      }
      if (item.value == 'jajp') {
        this.columns = [
          { value: '瘙痒', text: '痒（かゆ）み' },
          { value: '腹泻拉稀', text: '下痢' },
          { value: '眼耳发炎', text: '目や耳の炎症' },
          { value: '皮肤红肿发炎', text: '皮ふが赤く腫れ、炎症を起こす' },
          { value: '皮膚丘疹紅斑', text: '皮ふに丘疹（ブツブツ）や赤い斑点' },
          { value: '其他', text: 'その他' },]
      }

      if (item.value == 'kokr') {
        this.columns = [
          { value: '瘙痒', text: '가려움' },
          { value: '腹泻拉稀', text: '설사' },
          { value: '眼耳发炎', text: '눈, 귀 염증' },
          { value: '皮肤红肿发炎', text: '피부발적' },
          { value: '皮膚丘疹紅斑', text: '뾰루지' },
          { value: '其他', text: '기타' },]
      }


      this.i18nShwo = false;

    },
    onSubmit() {
      console.log(this.cascaderValue)
      var userinfo = this.userinfo;
      if (userinfo.customerName == '' || userinfo.customerName.length == 0) {
        showToast(this.$t('custom.forms.fieldcustomerName.placeholder'));
        return;
      }
      if (userinfo.gender == -1) {
        showToast(this.$t('custom.forms.fieldgender.placeholder'));
        return;
      }
      if (this.userinfo.customerAge == '') {
        showToast(this.$t('custom.forms.fieldcustomerAge.placeholder'));
        return;
      }
      if (this.userinfo.acquisitionDate == '') {
        showToast(this.$t('custom.forms.fieldacquisitionDate.placeholder'));
        return;
      }
      if (this.userinfo.sampleSendingUnit == '') {
        showToast(this.$t('custom.forms.fieldSampleSendingUnit.placeholder'));
        return;
      }

      if (userinfo.contactInformation == '' || userinfo.contactInformation.length == 0) {
        showToast(this.$t('custom.forms.fieldContactInformation.placeholder'));
        return;
      }


      if (userinfo.country == '' || userinfo.country.length == 0) {
        showToast(this.$t('custom.forms.fieldarea.placeholder'));
        return;
      }

      var data = this.userinfo;
      submitHumanApi(data)
        .then((res) => {
          if (res.code == 200) {
            showSuccessToast('Submission successful');
            this.$router.push({ path: '/successPage' })
          } else {
            showFailToast(res.msg);
          }
        })
        .catch((err) => { })
        .finally(() => {

        });
    },
    onFinishFun(selectedOptions) {
      this.show = false;
      var text = '';


      if (selectedOptions.selectedOptions[0]) {
        this.userinfo.country = selectedOptions.selectedOptions[0].code

        if (this.language == 'zh') {
          text = selectedOptions.selectedOptions[0].dictValue;
        } else if (this.language == 'en') {
          text = selectedOptions.selectedOptions[0].translate;
        } else if (this.language == 'jajp') {
          text = selectedOptions.selectedOptions[0].japanese;
        } else if (this.language == 'kokr') {
          text = selectedOptions.selectedOptions[0].korean;
        }



      }



      if (selectedOptions.selectedOptions[1]) {
        this.userinfo.economize = selectedOptions.selectedOptions[1].code

        if (this.language == 'zh') {
          text = text + '/' + selectedOptions.selectedOptions[1].dictValue;
        } else if (this.language == 'en') {
          text = text + '/' + selectedOptions.selectedOptions[1].translate;
        } else if (this.language == 'jajp') {
          text = text + '/' + selectedOptions.selectedOptions[1].japanese;
        }
        else if (this.language == 'kokr') {
          text = text + '/' + selectedOptions.selectedOptions[1].korean;
        }

      }

      if (selectedOptions.selectedOptions[2]) {
        this.userinfo.market = selectedOptions.selectedOptions[2].code
        if (this.language == 'zh') {
          text = text + '/' + selectedOptions.selectedOptions[2].dictValue;
        } else if (this.language == 'en') {
          text = text + '/' + selectedOptions.selectedOptions[2].translate;
        } else if (this.language == 'jajp') {
          text = text + '/' + selectedOptions.selectedOptions[2].japanese;
        } else if (this.language == 'kokr') {
          text = text + '/' + selectedOptions.selectedOptions[2].korean;
        }

      }

      if (selectedOptions.selectedOptions[3]) {
        this.userinfo.county = selectedOptions.selectedOptions[3].code

        if (this.language == 'zh') {
          text = text + '/' + selectedOptions.selectedOptions[3].dictValue;
        } else if (this.language == 'en') {
          text = text + '/' + selectedOptions.selectedOptions[3].translate;
        } else if (this.language == 'jajp') {
          text = text + '/' + selectedOptions.selectedOptions[3].japanese;
        } else if (this.language == 'kokr') {
          text = text + '/' + selectedOptions.selectedOptions[3].korean;
        }



      }

      this.dictValue = text;


    },
    cascaderClosFun() {
      this.show = false;
      this.dictValue = '';
      this.userinfo.country = '';
      this.userinfo.economize = '';
      this.userinfo.market = '';
      this.userinfo.county = '';
    },
    onBirthDateSUbmit() {
      this.userinfo.acquisitionDate = this.currentDate.join('-');
      this.showPicker = false;
    },
    getQrCode(param) {
      if (param == undefined || param == '') {
        this.$router.push({ path: '/404' })
      }

      qrCodeApi(param)
        .then((res) => {
          var qrData = res.data;
          if (qrData.whetherEnter == 1) {
            this.$router.push({ path: '/submitted' })
          }
          if (qrData.qrType == 2) {
            this.$router.push({ path: '/pet', query: { code: param } })
          }
          this.userinfo.qrcodeInfoId = param;

        })
        .catch((err) => {
          this.$router.push({ path: '/404' })
        })
        .finally(() => { });
    },
    treePageApiFun() {
      treePageApi(null)
        .then((res) => {
          this.options = res.data;

        })
        .catch((err) => {

        })
        .finally(() => { });
    }

  },
  created() {
    this.getQrCode(this.$route.query.code);
    this.treePageApiFun();
  }
};
</script>
  
<style scoped>
.nav-bar-div {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.maintext {

  height: 100%;
  background: url("./../../asset/img/2.jpg") no-repeat left;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  padding-bottom: 100px;

}

.title-div {
  text-shadow: rgb(19, 15, 127) 0 0 10px;
  color: rgb(255, 235, 255);
  font-size: 40px;
  text-align: center;
  height: 10vh;
}

.introduce-div {
  background-color: rgb(255, 255, 255);

  width: 90%;
  margin-left: 5%;
  border-radius: 10px;
  line-height: 25px;
  padding-bottom: 1%;
  padding-top: 1%;
}

.introduce-div p {
  text-indent: 2em;

  padding-left: 20px;
  padding-right: 20px;
}

.content-div {
  background-color: rgb(255, 255, 255);

  width: 90%;
  margin-left: 5%;
  border-radius: 10px;
  line-height: 25px;
  margin-top: 30px;
}

.content-field {
  padding-top: 20px;
}

.groupnannv {
  margin-top: 15px;
  margin-left: 30%;
}

.field-label {
  border: 1px solid black;
}

.button-div {
  margin-top: 5vh;
  padding-bottom: 5vh;
  width: 70%;
  margin-left: 15%;
}
</style>
 
<style lang="less" scoped>
/deep/ .custom-radio-group,
.custom-checkbox-group {
  width: 100%;

  // 单选框，复选框文字样式
  .van-radio__label,
  .van-checkbox__label {
    text-align: center;
    margin-left: 0;
    height: 33px;
    line-height: 33px;
    background-color: #f6f8fa;
    width: 100%;
  }

  .van-radio,
  .van-checkbox {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
  }

  // 去掉原本的勾选样式
  .van-radio__icon .van-icon,
  .van-checkbox__icon .van-icon {
    border: 0;
    display: none;
  }

  // 选中之后的文字颜色和背景色设置
  .van-radio__icon--checked+.van-radio__label,
  .van-checkbox__icon--checked+.van-checkbox__label {
    color: #617af0;
    background-color: #ecf0ff;
  }
}
</style>
  
import { axios } from '@/utils/request'

// 获取二维码
export function qrCodeApi (params) {
    return axios.get('/qrCode/qrCode?code='+params)
}

export function submitHumanApi (params) {
    return axios.post('/qrCode/submitHuman',params)
}
export function submitPetApi (params) {
    return axios.post('/qrCode/submitPet',params)
}
export function treePageApi (params) {
    return axios.get('/qrCode/treePage',params)
}

export function wxBuyApi (params) {
    return axios.post('/wx/buy',params)
}




